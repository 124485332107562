
































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import moment from 'moment'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',
      'filter',
      'report_data',
    ]),
    ...mapGetters(STORE_KEY, [
      'summary',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ReportByChannel extends Vue {

  public from: string = ''
  public to: string = ''

  get titleStack() {
    return [
      'Отчет по каналам поступления чеков',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    const date_from: any = 'from' in this.$route.query ? this.$route.query.from : null
    const date_to: any = 'to' in this.$route.query ? this.$route.query.to : null
    if (date_from && date_to) {
      this.from = date_from
      this.to = date_to
      this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.query)
      console.log(this.$store)
    }
  }

  // ---------------------------------------------------------------------------

  public setPeriod() {
    // const date_from: any = 'from' in this.$route.query ? this.$route.query.from : moment().format('YYYY-MM-DD');
    // const date_to: any = 'to' in this.$route.query ? this.$route.query.to : moment().format('YYYY-MM-DD');
    // if (date_from && date_to) {
    //   this.from = date_from;
    //   this.to = date_to;
    // }
    this.$router.push({
      query: {
        from: this.from,
        to: this.to,
      },
    })
  }

  // ---------------------------------------------------------------------------

  public created() {
    // console.log(this.$store._modules.root._children)
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
