import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse } from 'axios'

interface ReportByChannelState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  report_data: any[];
}

const $state: ReportByChannelState = {
  loading: false,
  loaded: false,
  fatalError: false,

  report_data: [],
}

export const $actions: ActionTree<ReportByChannelState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      params.date_from = query.from
      params.date_to = query.to

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/reports/by_channel', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

}

export const $mutations: MutationTree<ReportByChannelState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEMS_LIST(state, data) {
    const _report_data: any = {}
    data.data.forEach((item: any) => {
      if (!_report_data[item.merchant_id]) {
        _report_data[item.merchant_id] = {
          id: item.merchant_id,
          name: item.merchant,
          outlet_count: 0,
          client_input_sum: 0,
          client_input_count: 0,
          email_sum: 0,
          email_count: 0,
          qr_sum: 0,
          qr_count: 0,
          total_sum: 0,
          total_count: 0,
          items: {},
        }
      }
      if (!_report_data[item.merchant_id].items[item.outlet_id]) {
        _report_data[item.merchant_id].items[item.outlet_id] = {
          outlet_id: item.outlet_ids,
          address: item.outlet_address,
          address_half: item.outlet_address_half,
          client_input_sum: 0,
          client_input_count: 0,
          email_sum: 0,
          email_count: 0,
          qr_sum: 0,
          qr_count: 0,
          total_sum: 0,
          total_count: 0,
        }
      }
      _report_data[item.merchant_id][item.channel + '_sum'] += item.total_sum
      _report_data[item.merchant_id][item.channel + '_count'] += item.total_count
      _report_data[item.merchant_id].total_sum += item.total_sum
      _report_data[item.merchant_id].total_count += item.total_count
      _report_data[item.merchant_id].outlet_count += 1
      _report_data[item.merchant_id].items[item.outlet_id][item.channel + '_sum'] += item.total_sum
      _report_data[item.merchant_id].items[item.outlet_id][item.channel + '_count'] += item.total_count
      _report_data[item.merchant_id].items[item.outlet_id].total_sum += item.total_sum
      _report_data[item.merchant_id].items[item.outlet_id].total_count += item.total_count
    })
    state.report_data = Object.values(_report_data).map((item: any) => {
      item.items = Object.values(item.items)
      return item
    })
  },
}

export const $getters:
  GetterTree<ReportByChannelState, RootState> = {
  summary(state) {
    const sum = {
      outlet_count: 0,
      client_input_sum: 0,
      client_input_count: 0,
      email_sum: 0,
      email_count: 0,
      qr_sum: 0,
      qr_count: 0,
      total_sum: 0,
      total_count: 0,
    }
    state.report_data.forEach((obj) => {
      sum.outlet_count += obj.outlet_count
      sum.client_input_count += obj.client_input_count
      sum.client_input_sum += obj.client_input_sum / 100.0
      sum.email_count += obj.email_count
      sum.email_sum += obj.email_sum / 100.0
      sum.qr_count += obj.qr_count
      sum.qr_sum += obj.qr_sum / 100.0
      sum.total_count += obj.total_count
      sum.total_sum += obj.total_sum / 100.0
    })
    return sum
  },
}

export const STORE_KEY = '$_report_by_channel'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
